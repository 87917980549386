import React, { Component } from 'react';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import { Clickable } from '@turbocomplynpm/clickable';
import { Flex } from '@turbocomplynpm/flex';
import type { ErrorBoundaryType } from './error-boundary.types';
import ErrorText from './error-text';
import * as styles from './error-boundary.module.css';
import { Paragraph } from '../typography/typography';

const cx = classNames.bind(styles);
type Props = {
  children: ReactNode;
} & ErrorBoundaryType;
interface ErrorState {
  hasError: boolean;
  error: string;
}
class ErrorBoundary extends Component<Props, ErrorState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: '',
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { resetKeys } = this.props;
    const { hasError } = this.state;
    if (
      resetKeys &&
      prevProps.resetKeys &&
      prevProps.resetKeys.toString() !== resetKeys.toString() &&
      hasError
    ) {
      this.resetError();
    }
  }

  componentDidCatch(error: Error) {
    const errorMessage = error.message ?? 'Something went wrong.';
    this.setState({
      error: errorMessage,
    });
  }

  resetError = () => {
    this.setState({
      hasError: false,
      error: '',
    });
  };

  handleClear: React.MouseEventHandler<HTMLElement> = (event) => {
    const { reset } = this.props;
    if (typeof reset === 'function') {
      reset(event);
    }
    this.resetError();
  };

  handleReloadPage = () => {
    window.location.reload();
  };

  render() {
    const { hasError, error } = this.state;
    const { children, reset } = this.props;

    if (hasError) {
      return (
        <div className={cx('error')}>
          <ErrorText errorMessage={error} handleClear={this.handleClear} />
          {typeof reset === 'undefined' && (
            <Flex row>
              <Paragraph>Try</Paragraph>
              <Clickable
                onClick={this.handleReloadPage}
                className={cx('click')}
              >
                <Paragraph link>refreshing</Paragraph>
              </Clickable>
              <Paragraph>the page.</Paragraph>
            </Flex>
          )}
        </div>
      );
    }
    return children;
  }
}
export default ErrorBoundary;
