import queryString from 'query-string';

interface PageQueryStrings {
  companyName: string;
  gstins: string[];
}
interface UrlParams extends PageQueryStrings {
  cin: string;
  isRelative?: boolean;
}

const pageQueryStrings = ({ companyName, gstins }: PageQueryStrings) =>
  queryString.stringify({
    companyName,
    gstins,
  });

interface PageSummaryQueryStrings extends PageQueryStrings {
  finYear: string;
}
const pageSummaryQueryStrings = ({
  companyName,
  gstins,
  finYear,
}: PageSummaryQueryStrings) =>
  queryString.stringify({
    companyName,
    gstins,
    finYear,
  });

export const getReportPageUrl = ({ cin, companyName, gstins }: UrlParams) => {
  const pageQuery = pageQueryStrings({ companyName, gstins });
  return `/report/${cin}?${pageQuery}`;
};

interface GstUrlParams extends UrlParams {
  finYear: string;
}

export const getSummaryGstPageUrl = ({
  cin,
  companyName,
  finYear,
  gstins,
  isRelative,
}: GstUrlParams) => {
  const pageQuery = pageSummaryQueryStrings({ companyName, gstins, finYear });
  if (isRelative) {
    return `gst?${pageQuery}`;
  }
  return `/summary/${cin}/gst?${pageQuery}`;
};

interface RocUrlParams extends UrlParams {
  finYear: string;
}

export const getSummaryRocPageUrl = ({
  cin,
  companyName,
  finYear,
  gstins,
  isRelative,
}: RocUrlParams) => {
  const pageQuery = pageSummaryQueryStrings({ companyName, gstins, finYear });
  if (isRelative) {
    return `roc?${pageQuery}`;
  }
  return `/summary/${cin}/roc?${pageQuery}`;
};

export const getSummaryTracesPageUrl = ({
  cin,
  companyName,
  gstins,
  isRelative,
}: UrlParams) => {
  const pageQuery = pageQueryStrings({ companyName, gstins });
  if (isRelative) {
    return `traces?${pageQuery}`;
  }
  return `/summary/${cin}/traces?${pageQuery}`;
};

export const getSummaryPfPageUrl = ({
  cin,
  companyName,
  gstins,
  isRelative,
}: UrlParams) => {
  const pageQuery = pageQueryStrings({ companyName, gstins });
  if (isRelative) {
    return `pf?${pageQuery}`;
  }
  return `/summary/${cin}/pf?${pageQuery}`;
};
